import _codes from "./codes.json";
var exports = {};

/**
 * Module dependencies.
 * @private
 */
var codes = _codes;
/**
 * Module exports.
 * @public
 */

exports = status; // status code to message map

status.STATUS_CODES = codes; // array of status codes

status.codes = populateStatusesMap(status, codes); // status codes for redirects

status.redirect = {
  300: true,
  301: true,
  302: true,
  303: true,
  305: true,
  307: true,
  308: true
}; // status codes for empty bodies

status.empty = {
  204: true,
  205: true,
  304: true
}; // status codes for when you should retry the request

status.retry = {
  502: true,
  503: true,
  504: true
};
/**
 * Populate the statuses map for given codes.
 * @private
 */

function populateStatusesMap(statuses, codes) {
  var arr = [];
  Object.keys(codes).forEach(function forEachCode(code) {
    var message = codes[code];
    var status = Number(code); // Populate properties

    statuses[status] = message;
    statuses[message] = status;
    statuses[message.toLowerCase()] = status; // Add to array

    arr.push(status);
  });
  return arr;
}
/**
 * Get the status code.
 *
 * Given a number, this will throw if it is not a known status
 * code, otherwise the code will be returned. Given a string,
 * the string will be parsed for a number and return the code
 * if valid, otherwise will lookup the code assuming this is
 * the status message.
 *
 * @param {string|number} code
 * @returns {number}
 * @public
 */


function status(code) {
  if (typeof code === "number") {
    if (!status[code]) throw new Error("invalid status code: " + code);
    return code;
  }

  if (typeof code !== "string") {
    throw new TypeError("code must be a number or string");
  } // '403'


  var n = parseInt(code, 10);

  if (!isNaN(n)) {
    if (!status[n]) throw new Error("invalid status code: " + n);
    return n;
  }

  n = status[code.toLowerCase()];
  if (!n) throw new Error("invalid status message: \"" + code + "\"");
  return n;
}

export default exports;